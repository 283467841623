.the_page {
  margin-top: 40px;
  height: auto;
  width: 100vw;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}

.cartPage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
}
.emptyCart
{
  height: 50vh;
  width: 100vw;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cartItem {
  width: 700px;
  height: 250px;
  display: flex;
  justify-content: space-around;
  background-color: bisque;
  align-items: center;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  margin: 30px;
}

.cartItem img {
  margin-left: 30px;
  border-radius: 10px;
  width: 200px;
}

.cartItem .description {
  width: 50%;
  font-size: 30px;
}

.countHandler input {
  width: 40px;
  text-align: center;
  font-weight: bolder;
}

.total {
  margin-top: 10px;
  margin-left: 10px;
  font-size: larger;
  font-weight: bold;
}

.checkout button {
  width: 200px;
  height: 50px;
  background-color: rgb(19, 19, 19);
  color: white;
  border: none;
  border-radius: 8px;
  margin: 10px;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
}

.checkoutComp {
  display: flex;
  margin-right: 50px;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  padding-left: 50px;
  border-left: 1px solid black;
}

h2 {
  height: 50px;
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: rgb(124, 124, 124);
}

.dataInput {
  padding: 3%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

.dataInput label {
  display: flex;
  justify-content: space-between;
  width: 300px;
  font-size: large;
  font-weight: bold;
}

.submitButton {
  height: 50px;
  width: 150px;
  color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-color: bisque;
  border-radius: 15px;
  font-size: large;
  font-weight: bold;
}

/* Media Query for Mobile Devices */
@media only screen and (max-width: 600px) {
  .the_page {
    flex-direction: column;
    align-items: center;
  }

  .cartPage {
    width: 100%;
  }

  .cartItem {
    width: 90%;
    height: auto;
    flex-direction: column;
  }

  .cartItem img {
    width: 100%;
    height: auto;
    margin-left: 0;
    border-radius: 10px;
  }

  .cartItem .description {
    width: 100%;
    font-size: 20px;
  }

  .checkoutComp {
    width: 100%;
    height: 100vh;
    margin-right: 0;
    padding-left: 0;
    border-left: none;
    padding-top: 20px;
  }

  .checkout button {
    width: 100%;
    
  }

  .total {
    margin-left: 0;
    text-align: center;
  }
}

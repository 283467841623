.App {
  width: 100%;
  height: auto;
  min-height: 100vh;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

body {
  padding: 0;
  margin: 0;
}
.groundContent 
{
  padding-top: 5%;
  background-color: rgb(255, 255, 255);
  height: 100%;
}
.navbar {
  z-index: 999;
  position: fixed;
  width: 100%;
  height: 80px;
  background-color: rgb(255, 196, 19);
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  bottom: 0;
  
}

.Title
{
 user-select: none;
}
.links
{
  margin-right: 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 80px;
  flex-direction: row;
}

@media only screen and (max-width: 600px)
{
  .Title{
    font-size: 10px;
  }
}



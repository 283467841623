.page
{
    display: flex;
    flex-direction: column;
    height: 30vh;
    justify-content: space-around;
}
.contact {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .call-button {
    font-size: 18px;
    padding: 10px 20px;
    cursor: pointer;
    width: 50%;
    background-color: #4CAF50; /* Green */
    color: white;
    border: none;
    border-radius: 5px;
  }
  
  .call-button:hover {
    background-color: #45a049;
  }
  a
  {
    font-size: larger;
  }
  
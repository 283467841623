.titleBar
{
    z-index: 999;
    position: fixed;
    width: 100%;
    height: 100px;
    background-color: rgb(232, 229, 22);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow-x: auto;
}
@media only screen and (max-width: 900px)
{
    .titleBar
    {
        height: 80px;
    }
    .logo
    {
        height: 60px;
        width: 60px;
    }
}
.logoPort 
{
    height: 100%;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo
{
    height: 95px;
    width: 95px;
    background-image: url("../../assets/Pictures/logo.png"); 
    background-size: cover;
    background-position: center;
}
.TitlePort
{
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 50px;
    font-weight: bold;
}
.emptySpace
{
    height: 100%;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
}
@media only screen and (max-width: 600px)
{
    .titleBar
    {
        height: 70px;
        justify-content: space-between;
    }
    .TitlePort
    {
        font-size: 25px;
    }
    .logo{
        height: 60px;
        width: 60px;
    }
    .emptySpace
    {
        font-size: 10px;
    }
   
}
/* General Styles */
.shop {
  margin-top: 50px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}


.products {
  margin-left: 10%;
  width: 100vw;
  height: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 20px; /* Add some gap between grid items */
  justify-content: center;
}

.product {
  padding: 3%;
  border-radius: 15px;
  width: 70%; /* Use full width inside the grid */
  height: 80%; /* Let the height be determined by the content */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(255, 180, 59);
  transition: background-color 0.2s;
}

.product:hover {
  background-color: rgb(249, 167, 35);
}

.product img {
  height: 100%;
  width: 30%;
  overflow: hidden;
  border-radius: 5px;
  width: 200px;
  box-shadow: 5px 0 5px 4px rgb(233, 162, 55);
}

.product .description {
  height: 80%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-end;
}

.product:hover {
  cursor: pointer;
}

.addToCartBttn {
  background-color: transparent;
  border: 2px solid rgb(19, 19, 19);
  min-width: 100px;
  padding: 5px 10px; /* Combined padding properties */
  border-radius: 15px;
}

.addToCartBttn:hover {
  background-color: rgb(19, 19, 19);
  color: white;
  cursor: pointer;
}

/* Mobile Styles */
@media only screen and (max-width: 600px) {
  .shop {
    margin-bottom: 80px;
    justify-content: flex-start; /* Align items to the top on mobile */
    align-items: flex-start;
  }

  .products {
    margin-left: 0; /* Remove margin on mobile */
    width: 100%;
    display: flex;
    flex-direction: column; /* Stack items vertically */
    gap: 0; /* Add some gap between flex items */
  }

  .product {
    width: 90%; /* Adjust width for mobile */
    height: 100px;
    margin: 2% auto; /* Center items and add vertical spacing */
    flex-direction: row; /* Stack content vertically */
    align-items: center; /* Center content */
    text-align: center; /* Center text */
  }

  .product img {
    width: 50%; /* Make image responsive */
    height: 90%; /* Maintain aspect ratio */
  }

  .product .description {
    align-items: center; /* Center text */
    text-align: center; /* Center text */
  }
}

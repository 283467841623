.ThankYou
{
    height: 100vh;
    width: auto;
    background-color: aqua;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    flex-direction: column;
}
@keyframes slide {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100px);
    }
}

.topPortion {
    height: auto;
    width: 30%;
    display: flex;
    justify-content: start;
}

.waitingLogo {
    animation: slide 2s ease-in-out infinite;
}
.bottomPortion
{
    text-align: center;
}